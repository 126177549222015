<template>
  <div class="container">
    <h1>日上券 - 前台购券</h1>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button type="primary" size="small" icon="el-icon-refresh" @click="handleRefresh">刷新券状态</el-button>
        <el-button type="primary" size="small" icon="el-icon-download" @click="handleExport">导出 Excel</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="code" label="券码" min-width="240" />
        <el-table-column prop="kind" label="券类型" min-width="120" />
        <el-table-column prop="startTime" label="有效期开始时间" min-width="140">
          <template #default="scope">
            {{ dateFormat(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="有效期结束时间" min-width="140">
          <template #default="scope">
            {{ dateFormat(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" min-width="120" />
        <el-table-column prop="membercode" label="会员码" min-width="240" />
        <el-table-column prop="source" label="支付方式" min-width="100">
          <template #default="scope">
            {{ sourceFormat(scope.row.source) }}
          </template>
        </el-table-column>
        <el-table-column prop="salesman" label="销售员" min-width="100" />
        <el-table-column prop="recommend" label="推广ID" min-width="100" />
        <el-table-column prop="price" label="销售价格" min-width="100" />
        <el-table-column prop="status" label="状态" min-width="100">
          <template #default="scope">
            {{ statusFormat(scope.row.status) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
    <el-dialog title="正在刷新券状态" v-model="dialogVisible" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" center width="600px">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="refreshPercent"><span>{{ progressText }}</span>
      </el-progress>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getVipCodeList,
    getRefreshList,
    refreshVipCode
  } from "@/api/vipCode"
  import {
    formatJsonDate
  } from "../../utils/dateFormat"
  import {
    createFile,
    download
  } from '../../utils/excel'

  export default {
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0,
        dialogVisible: false,
        refreshItems: [],
        refreshIndex: 0,
        refreshPercent: 0,
        progressText: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getVipCodeList(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      sourceFormat(source) {
        switch (source) {
          case 1:
            return '微信支付'
          case 2:
            return '余额支付'
          default:
            return source
        }
      },
      statusFormat(status) {
        switch (status) {
          case 0:
            return '未使用'
          case 1:
            return '已使用'
          case 2:
            return '已过期'
          default:
            return status
        }
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handleRefresh() {
        getRefreshList([1, 2])
          .then(res => {
            if (res.success) {
              this.refreshItems = res.items

              if (this.refreshItems.length > 0) {
                this.refreshIndex = 0
                this.dialogVisible = true
                this.beginRefresh()
              }
            }
          })
      },
      beginRefresh() {
        if (this.refreshIndex == this.refreshItems.length) {
          this.dialogVisible = false
          this.init()
          return
        }

        this.progressText = '正在刷新 ' + (this.refreshIndex + 1) + ' / ' + this.refreshItems.length
        this.refreshPercent = this.refreshIndex / this.refreshItems.length * 100

        refreshVipCode(this.refreshItems[this.refreshIndex].code)
          .then(() => {
            if (this.dialogVisible) {
              this.refreshIndex++
              this.beginRefresh()
            }
          })
      },
      handleExport() {
        getVipCodeList(this.params.KeyWords, 0, 0)
          .then(res => {
            if (res.success) {
              let exportData = res.items.map(d => {
                return {
                  '券码': d.code,
                  '券类型': d.kind,
                  '有效期开始时间': this.dateFormat(d.startTime),
                  '有效期结束时间': this.dateFormat(d.endTime),
                  '手机号': d.phone,
                  '会员码': d.membercode,
                  '支付方式': this.sourceFormat(d.source),
                  '销售员': d.salesman,
                  '推广ID': d.recommend,
                  '销售价格': d.price,
                  '状态': this.statusFormat(d.status)
                }
              })

              let columnWidths = [{
                wpx: 180
              }, {
                wpx: 130
              }, {
                wpx: 130
              }, {
                wpx: 130
              }, {
                wpx: 90
              }, {
                wpx: 180
              }, {
                wpx: 80
              }, {
                wpx: 60
              }, {
                wpx: 100
              }, {
                wpx: 60
              }, {
                wpx: 60
              }]

              var file = createFile(exportData, columnWidths)
              download(file, '日上券', document)
            }
          })
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
